import 'core-js/features/map';
import 'core-js/features/set';

import React from 'react';
import ReactDOM from 'react-dom';
import connect from '@vkontakte/vk-connect';

import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { loadUser } from './js/store/user/actions';
import { loadTasks } from './js/store/tasks/actions';
import rootReducer from './js/store';

import App from './App';
import '@vkontakte/vkui/dist/vkui.css';
import packageJson from '../package.json';

// DEV
window.is_dev = false;
window.app_id = packageJson.vk_app_id;

// Init VK  Mini App
connect.send('VKWebAppInit');

// Scheme
connect.subscribe(({ detail: { type, data } }) => {
    if (type === 'VKWebAppUpdateConfig') {
        const schemeAttribute = document.createAttribute('scheme');
        schemeAttribute.value = data.scheme ? data.scheme : 'bright_light';
        document.body.attributes.setNamedItem(schemeAttribute);
    }
});

// Store
const store = createStore(rootReducer, applyMiddleware(thunk));
store.dispatch(loadUser());
store.dispatch(loadTasks());
// store.dispatch(loadRating());

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));
