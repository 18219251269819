import React from 'react';
import PropTypes from 'prop-types';

import Div from '@vkontakte/vkui/dist/components/Div/Div';
import { Input } from '@vkontakte/vkui';

import '../../styles/quest.scss';
import '../../styles/quest-image.scss';

const QuestTextCorrection = ({ text, answer, handle }) => {
    return (
        <Div className="quest">
            <div className="quest__title">Отредактируйте текст</div>
            <div className="quest__subtitle">{text}</div>
            <div className="quest__box">
                <Input
                    value={answer}
                    type="text"
                    onChange={(e) => handle(e.target.value)}
                />
            </div>
        </Div>
    );
};

QuestTextCorrection.propTypes = {
    text: PropTypes.string,
    answer: PropTypes.any,
    handle: PropTypes.func.isRequired,
};

export default QuestTextCorrection;
