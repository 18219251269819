import React from 'react';

const SvgDoneIcon = props => (
    <svg viewBox="0 0 28 28" {...props}>
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h28v28H0z" />
            <path
                fill="#3F8AE0"
                d="M11 18.586l-5.293-5.293a1 1 0 10-1.414 1.414l6 6a1 1 0 001.414 0l12-12a1 1 0 10-1.414-1.414L11 18.586z"
            />
        </g>
    </svg>
);

export default SvgDoneIcon;
