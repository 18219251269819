import * as types from './actionTypes';

const initialState = {
    todo: [],
    all: [],
    prev: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_QUESTS: {
            return {
                ...state,
                todo: action.quests,
            };
        }
        case types.NEXT_QUEST: {
            const prev = state.todo.find((x) => x.id === action.prevId);

            return {
                ...state,
                todo: state.todo.filter((x) => x.id !== action.prevId),
                all: [...state.all, { ...prev, answer: action.answer }],
                prev: [...state.prev, { ...prev, answer: action.answer }],
            };
        }
        case types.PREV_QUEST: {
            const copyAll = state.all.slice();
            copyAll.pop();

            return {
                ...state,
                todo: state.all.length ? [state.all[state.all.length - 1]] : [],
                all: copyAll,
            };
        }
        default: return state;
    }
};
