import React from 'react';
import PropTypes from 'prop-types';

import Cell from '@vkontakte/vkui/dist/components/Cell/Cell';
import Avatar from '@vkontakte/vkui/dist/components/Avatar/Avatar';

import '../../styles/rating-item.scss';

const RatingItem = ({ position, avatar, score, name }) => (
    <div className="rating-item">
        <div className="rating-item__position">{position}</div>
        <Cell
            before={
                <Avatar
                    size={48}
                    src={avatar}
                />
            }
            description={score}
        >
            {name}
        </Cell>
    </div>
);

RatingItem.propTypes = {
    position: PropTypes.any,
    avatar: PropTypes.string,
    score: PropTypes.string,
    name: PropTypes.string,
};

RatingItem.defaultProps = {
    position: 0,
    avatar: '',
    score: '',
    name: '',
};

export default RatingItem;
