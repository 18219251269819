import React from 'react';

import { Stack, Page } from 'vkui-navigator/dist';

// Panels
import Home from './js/containers/Home';
import TaskAbout from './js/containers/TaskAbout';
import Quest from './js/containers/Quest';
import QuestFinish from './js/containers/QuestFinish';

// Modals
import PolygonName from './js/containers/modals/PolygonName';

const App = () => (
    <Stack
        activePage="main"
        modals={[
            <PolygonName id="polygon-name" />,
        ]}
    >
        <Page
            id="main"
            activePanel="home"
        >
            <Home id="home" />
            <TaskAbout id="task-about" />
        </Page>
        <Page
            id="quest"
            activePanel="quest"
            header={false}
        >
            <Quest id="quest" />
            <QuestFinish id="quest-finish" />
        </Page>
    </Stack>
);

export default App;
