import React from 'react';

const SvgRecentOutline = props => (
    <svg viewBox="0 0 28 28" {...props}>
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h28v28H0z" />
            <path
                fill="#3F8AE0"
                fillRule="nonzero"
                d="M14 2c6.626 0 12 5.372 12 12s-5.374 12-12 12C7.372 26 2 20.628 2 14S7.372 2 14 2zm0 2C8.477 4 4 8.477 4 14s4.477 10 10 10c5.522 0 10-4.478 10-10S19.522 4 14 4zm-.5 4a1 1 0 011 1v5.104l4.875 1.969a1 1 0 11-.75 1.854l-5.5-2.222a1 1 0 01-.625-.927V9a1 1 0 011-1z"
            />
        </g>
    </svg>
);

export default SvgRecentOutline;
