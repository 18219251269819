import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import Div from '@vkontakte/vkui/dist/components/Div/Div';
import FixedLayout from '@vkontakte/vkui/dist/components/FixedLayout/FixedLayout';

import Icon28CheckCircleOutline from '@vkontakte/icons/dist/28/check_circle_outline';

import '../../styles/quest-finish.scss';

const QuestFinish = ({ id, navigator }) => {
    const user = useSelector((state) => state.user);
    const task = useSelector((state) => state.tasks.list[state.tasks.active]);

    return (
        <Panel
            id={id}
            theme="white"
            style={{ position: 'fixed' }}
        >
            <div className="quest-finish">
                <Icon28CheckCircleOutline
                    width={36}
                    height={36}
                    fill="#3F8AE0"
                />
                <div className="quest-finish__title">Ваши ответы отправлены</div>
                <div className="quest-finish__stats">
                    <div className="quest-finish__item">
                        <div className="quest-finish__stats-title">Вы заработали</div>
                        <div className="quest-finish__stats-text">{task.statistic.earned}</div>
                    </div>
                    <div className="quest-finish__item">
                        <div className="quest-finish__stats-title">Рейтинг</div>
                        <div className="quest-finish__stats-text">{user.me.place} место</div>
                    </div>
                </div>
            </div>
            <FixedLayout vertical="bottom">
                <Div className="quest-actions__buttons-column">
                    <Button
                        size="xl"
                        onClick={() => navigator.goPage('main')}
                    >
                        Перейти к списку заданий
                    </Button>
                </Div>
            </FixedLayout>
        </Panel>
    );
};

QuestFinish.propTypes = {
    id: PropTypes.string.isRequired,
    navigator: PropTypes.any,
};

export default QuestFinish;
