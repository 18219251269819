import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import Div from '@vkontakte/vkui/dist/components/Div/Div';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import FixedLayout from '@vkontakte/vkui/dist/components/FixedLayout/FixedLayout';

import Icon24Cancel from '@vkontakte/icons/dist/24/cancel';
import * as API from '../services/api';
import { solveTask, reduceEarnedPoints } from '../store/tasks/actions';
import { loadRating } from '../store/user/actions';
import { loadQuests, nextQuest, prevQuest as goPrevQuest } from '../store/quests/actions';

import QuestHeader from '../components/QuestHeader';
import QuestChooseOption from '../components/QuestChooseOption';
import QuestChooseImage from '../components/QuestChooseImage';
import QuestDescribeImage from '../components/QuestDescribeImage';
import QuestCheck from '../components/QuestCheck';
import QuestCheckSpecial from '../components/QuestCheckSpecial';
import PolygonEditor from '../components/PolygonEditor';

import '../../styles/quest-actions.scss';
import QuestCompareTexts from '../components/QuestCompareTexts';
import QuestTextCorrection from '../components/QuestTextCorrection';

const Quest = ({ id, navigator }) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const task = useSelector(state => state.tasks.list[state.tasks.active]);
    const quest = useSelector(state => state.quests.todo[0]);
    const all = useSelector(state => state.quests.all);
    const [answer, setAnswer] = useState(null);
    const [autoToggleQuest, setAutoToggleQuest] = useState(false);

    const getNewQuest = useCallback(() => {
        navigator.showLoader();
        API.getQuestsByTask(task.id).then(quests => {
            if (quests.length > 0) {
                dispatch(loadQuests(quests));
                navigator.hideLoader();
            } else {
                navigator.go('quest-finish');
            }
        });
    }, [task]);

    const next = useCallback((value, force) => {
        const sentAnswer = force ? value : answer;
        navigator.showLoader();
        API.solveTask(task.id, quest.id, sentAnswer)
            .then(() => {
                dispatch(solveTask(task.id));
                dispatch(nextQuest(quest && quest.id, sentAnswer));
                dispatch(loadRating());
                setAnswer(null);

                getNewQuest();
            })
            .catch((e) => {
                console.log(e);
                navigator.hideLoader();
                navigator.showAlert({
                    title: 'Упс...',
                    description: 'Что-то пошло не так',
                });
            });
    }, [navigator, task.id, quest, answer, dispatch]);

    const back = useCallback(() => {
        dispatch(goPrevQuest());
        dispatch(reduceEarnedPoints(task.id));

        const timeout = setTimeout(() => {
            setAnswer(all[all.length - 1].answer);
            clearTimeout(timeout);
        }, 0);
    }, [dispatch, task.id, all]);

    useEffect(() => {
        document.querySelector('#quest').style.overflow = 'auto';
        // Сделано для того, чтобы панель не дергалась при первичном маунте
        getNewQuest();
    }, []);

    /* автоматический переход на следующую */
    const autoToggleToNextQuest = (value) => {
        if (autoToggleQuest) {
            setAnswer(value);
            next(value, true);
        } else {
            setAnswer(value);
        }
    };

    return (
        <Panel id={id} theme="white" style={{ overflow: 'hidden' }}>
            {/* Сделано для того, чтобы панель не дергалась при первичном маунте */}
            <QuestHeader
                value={task.statistic.solved_quests}
                maxValue={task.statistic.total_quests}
                earned={task.statistic.earned}
                manager={task.manager}
                place={user.me.place}
                questType={quest && quest.type}
                autoToggleQuest={autoToggleQuest}
                setAutoToggleQuest={setAutoToggleQuest}
                progressWidth="70%"
            />
            {
                {
                    'choose-option-image': (
                        <QuestChooseOption
                            image={quest && quest.image}
                            options={quest && quest.options}
                            answer={answer}
                            handle={autoToggleToNextQuest}
                        />
                    ),
                    'choose-option': (
                        <QuestChooseOption
                            options={quest && quest.options}
                            answer={answer}
                            handle={autoToggleToNextQuest}
                        />
                    ),
                    text: (
                        <QuestChooseOption
                            text={quest && quest.main_info || task.description}
                            options={quest && quest.options}
                            answer={answer}
                            handle={setAnswer}
                        />
                    ),
                    'choose-image': (
                        <QuestChooseImage
                            text={quest && quest.main_info || task.description}
                            images={quest && quest.images}
                            answer={answer}
                            handle={autoToggleToNextQuest}
                        />
                    ),
                    'describe-image': (
                        <QuestDescribeImage image={quest && quest.image} answer={answer || ''} handle={setAnswer} />
                    ),
                    'text-correction': (
                        <QuestTextCorrection
                            text={quest && quest.main_info}
                            answer={answer}
                            handle={setAnswer}
                        />
                    ),
                    'check-image': (
                        <QuestCheck
                            image={quest && quest.image}
                            options={quest && quest.options}
                            answer={answer || []}
                            handle={setAnswer}
                        />
                    ),
                    'check-image-labels': (
                        <QuestCheck
                            showAsLabels
                            image={quest && quest.image}
                            options={quest && quest.options}
                            answer={answer || []}
                            handle={setAnswer}
                        />
                    ),
                    'check-text': (
                        <QuestCheckSpecial
                            title={quest && quest.title}
                            mainInfo={quest && quest.main_info}
                            options={quest && quest.options}
                            answer={answer || []}
                            handle={setAnswer}
                        />
                    ),
                    'select-polygon': (
                        <PolygonEditor
                            title={quest && quest.title}
                            image={quest && quest.image}
                            onChangeName={(defaultName, hookSetName) => {
                                navigator.showModal('polygon-name', { defaultName, hookSetName });
                            }}
                            handle={setAnswer}
                        />
                    ),
                    'compare-texts': (
                        <QuestCompareTexts
                            text={quest && quest.main_info || task.description}
                            options={quest && quest.options}
                            answer={answer}
                            handle={setAnswer}
                        />
                    ),
                }[quest && quest.type]
            }
            <div style={{ height: '68px' }} />
            <FixedLayout vertical="bottom" className="quest-actions">
                <Div className="quest-actions__buttons">
                    <Button
                        size="xl"
                        level="secondary"
                        className="quest-actions__square-button"
                        onClick={() => navigator.goPage('main')}
                    >
                        <Icon24Cancel width={20} height={20} />
                    </Button>
                    <Button
                        disabled={all.length === 0}
                        size="xl"
                        level="secondary"
                        onClick={back}
                    >
                        Назад
                    </Button>
                    <Button size="xl" onClick={next} disabled={answer === null || answer.length === 0}>
                        Вперед
                    </Button>
                </Div>
            </FixedLayout>
        </Panel>
    );
};

Quest.propTypes = {
    id: PropTypes.string.isRequired,
    navigator: PropTypes.any,
};

export default Quest;
