export default {
    1: [
        {
            id: 1,
            task_id: 1,
            type: 'choose-option-image',
            image: 'https://picsum.photos/200/300?random=1',
            options: [
                {
                    name: 'Это котик',
                    value: '1',
                },
                {
                    name: 'Это не котик',
                    value: '2',
                },
            ],
        },
        {
            id: 2,
            task_id: 1,
            type: 'choose-option',
            options: [
                {
                    name: 'Это котик',
                    value: '1',
                },
                {
                    name: 'Это не котик',
                    value: '2',
                },
            ],
        },
        {
            id: 3,
            task_id: 1,
            type: 'choose-image',
            images: [
                {
                    image: 'https://picsum.photos/200/300?random=1',
                    value: '1',
                },
                {
                    image: 'https://picsum.photos/200/300?random=2',
                    value: '2',
                },
                {
                    image: 'https://picsum.photos/200/300?random=3',
                    value: '3',
                },
                {
                    image: 'https://picsum.photos/200/300?random=4',
                    value: '4',
                },
            ],
        },
        {
            id: 4,
            task_id: 1,
            type: 'describe-image',
            image: 'https://picsum.photos/200/300?random=1',
        },
        {
            id: 5,
            task_id: 1,
            type: 'check-image',
            image: 'https://picsum.photos/200/300?random=6',
            options: [
                {
                    name: 'Это котик',
                    value: '1',
                },
                {
                    name: 'Жирный котик',
                    value: '2',
                },
                {
                    name: 'Это не котик',
                    value: '3',
                },
                {
                    name: 'Животное',
                    value: '4',
                },
                {
                    name: 'Человек',
                    value: '5',
                },
                {
                    name: 'Криворукий разработчик',
                    value: '6',
                },
            ],
        },
        {
            id: 6,
            task_id: 1,
            type: 'check-image-labels',
            image: 'https://picsum.photos/200/300?random=6',
            options: [
                {
                    name: 'Это котик',
                    value: '1',
                },
                {
                    name: 'Жирный котик',
                    value: '2',
                },
                {
                    name: 'Это не котик',
                    value: '3',
                },
                {
                    name: 'Животное',
                    value: '4',
                },
                {
                    name: 'Человек',
                    value: '5',
                },
                {
                    name: 'Криворукий разработчик',
                    value: '6',
                },
            ],
        },
        {
            id: 7,
            task_id: 1,
            type: 'select-polygon',
            image: 'https://picsum.photos/200/300?random=7',
        },
    ],
    2: [],
    3: [],
    4: [],
};
