import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Div from '@vkontakte/vkui/dist/components/Div/Div';
import Search from '@vkontakte/vkui/dist/components/Search/Search';
import Checkbox from '@vkontakte/vkui/dist/components/Checkbox/Checkbox';

import '../../styles/quest.scss';
import '../../styles/quest-group.scss';

const QuestCheckSpecial = ({ title, mainInfo, options, answer, handle }) => {
    const [searchValue, setSearchValue] = useState('');

    return (
        <>
            <Div className="quest">
                <div className="quest__title">{title}</div>
                <div className="quest__text">
                    {mainInfo.split('\n').map(item => (
                        <p key={item}>{item}</p>
                    ))}
                </div>
            </Div>
            <div className="quest-group">
                <Div className="quest-group__title">Выберите подходящие варианты</Div>
                <div className="quest-group__content">
                    <Search theme="default" placeholder="Поиск" value={searchValue} onChange={setSearchValue} />
                    <div>
                        {options
                            .filter(o => o.name.toLowerCase().includes(searchValue.toLowerCase()))
                            .map(option => (
                                <Checkbox
                                    key={option.value}
                                    checked={answer.indexOf(option.value) !== -1}
                                    onClick={() => {
                                        if (answer.indexOf(option.value) === -1) {
                                            handle([...answer, option.value]);
                                        } else {
                                            handle(answer.filter(x => x !== option.value));
                                        }
                                    }}
                                >
                                    {option.name}
                                </Checkbox>
                            ))}
                    </div>
                    {options.filter(o => o.name.toLowerCase().includes(searchValue.toLowerCase())).length === 0 && (
                        <Div>Ничего не найдено</Div>
                    )}
                </div>
            </div>
        </>
    );
};

QuestCheckSpecial.propTypes = {
    title: PropTypes.string.isRequired,
    mainInfo: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.string,
        }),
    ),
    answer: PropTypes.any,
    handle: PropTypes.func.isRequired,
};

QuestCheckSpecial.defaultProps = {
    options: [
        {
            name: 'Это котик',
            value: '1',
        },
        {
            name: 'Жирный котик',
            value: '2',
        },
        {
            name: 'Это не котик',
            value: '3',
        },
        {
            name: 'Животное',
            value: '4',
        },
        {
            name: 'Человек',
            value: '5',
        },
        {
            name: 'Криворукий разработчик',
            value: '6',
        },
    ],
    answer: [],
};

export default QuestCheckSpecial;
