import React from 'react';
import PropTypes from 'prop-types';

import Div from '@vkontakte/vkui/dist/components/Div/Div';
import FormLayout from '@vkontakte/vkui/dist/components/FormLayout/FormLayout';
import Textarea from '@vkontakte/vkui/dist/components/Textarea/Textarea';

const QuestDescribeImage = ({ image, answer, handle }) => (
    <>
        <Div className="quest">
            <div className="quest__title">Что на картинке?</div>
            <div className="quest__box">
                <div className="quest__box-wrap">
                    <div className="quest-image" style={{ backgroundImage: `url('${image}')` }} />
                </div>
            </div>
        </Div>
        <FormLayout>
            <Textarea
                top="Ваш ответ"
                placeholder="Введите свой ответ"
                value={answer}
                onChange={(obj) => handle(obj.currentTarget.value)}
            />
        </FormLayout>
    </>
);

QuestDescribeImage.propTypes = {
    image: PropTypes.string,
    handle: PropTypes.func.isRequired,
    answer: PropTypes.string,
};

QuestDescribeImage.defaultProps = {
    image: 'https://picsum.photos/200/300?random=5',
    answer: null,
};

export default QuestDescribeImage;
