export const capitalize = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const wordPad = (num, t, ta, tov) => {
    num %= 100;
    if (num % 10 === 1 && (num < 10 || num > 20)) return t;
    if (num % 10 >= 2 && num % 10 <= 4 && (num < 10 || num > 20)) return ta;
    return tov;
};

export const numberWithSpaces = x => {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
};

export const daysToDate = (date, now = Date.now() / 1000) => {
    let delta = date - now;
    delta /= 60; // minues
    delta /= 60; // hours
    delta /= 24; // days
    return Math.floor(delta);
};

export const FireEvent = link => {
    const a = document.createElement('a');
    a.href = link;
    a.target = '_blank';
    a.rel = 'noopener noreferrer';

    a.dispatchEvent(
        new window.MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        }),
    );
};
