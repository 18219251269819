import * as types from './actionTypes';

export const loadQuests = (quests) => {
    return {
        type: types.LOAD_QUESTS,
        quests,
    };
};

export const nextQuest = (prevId, answer) => {
    return {
        type: types.NEXT_QUEST,
        prevId,
        answer,
    };
};

export const prevQuest = () => {
    return {
        type: types.PREV_QUEST,
    };
};
