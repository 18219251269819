import { combineReducers } from 'redux';

// Reducers
import userReducer from './user';
import tasksReducer from './tasks';
import questsReducer from './quests';

export default combineReducers({
    user: userReducer,
    tasks: tasksReducer,
    quests: questsReducer,
});
