import React from 'react';

const SvgStarOutline = props => (
    <svg viewBox="0 0 24 24" {...props}>
        <defs>
            <path
                id="star-outline_svg__a"
                d="M11.702 6.693l-1.375 3.25-3.154.311c-.42.042-.495.295-.176.567l2.493 2.125-.917 3.43c-.11.411.076.546.416.3L12 14.493l3.013 2.183c.343.248.526.112.416-.3l-.917-3.43 2.493-2.125c.315-.27.24-.526-.177-.567l-3.155-.31L12.3 6.693c-.165-.39-.433-.39-.598 0zm3.004 1.886l2.753.27c1.561.152 2.084 1.852.875 2.878l-2.136 1.812.8 2.98c.432 1.607-1.046 2.682-2.388 1.714l-2.609-1.882-2.608 1.882c-1.337.964-2.82-.105-2.389-1.713l.8-2.98-2.135-1.813c-1.212-1.03-.692-2.725.875-2.879l2.753-.27 1.225-2.886c.619-1.456 2.34-1.456 2.958 0l1.226 2.887z"
            />
        </defs>
        <g fill="none" fillRule="evenodd">
            <path d="M-2-2h28v28H-2z" />
            <path d="M0 0h24v24H0z" />
            <circle cx={12} cy={12} r={11.1} stroke="#3F8AE0" strokeWidth={1.8} />
            <use fill="#3F8AE0" fillRule="nonzero" xlinkHref="#star-outline_svg__a" />
        </g>
    </svg>
);

export default SvgStarOutline;
