import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ModalCard from '@vkontakte/vkui/dist/components/ModalCard/ModalCard';
import Textarea from '@vkontakte/vkui/dist/components/Textarea/Textarea';

const PolygonName = ({ id, onClose, navigator }) => {
    const [name, setName] = useState((navigator.params && navigator.params.defaultName) || '');

    const updateName = (_name) => {
        setName(_name);
        navigator.params.hookSetName(_name);
    };

    return (
        <ModalCard
            id={id}
            onClose={onClose}
            title="Название полигона"
            actions={[
                {
                    title: 'Сохранить',
                    type: 'primary',
                    action: onClose,
                },
            ]}
        >
            <Textarea value={name} onChange={(e) => updateName(e.currentTarget.value)} />
        </ModalCard>
    );
};

PolygonName.propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    navigator: PropTypes.any,
};

export default PolygonName;
