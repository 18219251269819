import React from 'react';
import PropTypes from 'prop-types';

import Div from '@vkontakte/vkui/dist/components/Div/Div';
import { Radio } from '@vkontakte/vkui';

import '../../styles/quest.scss';
import '../../styles/quest-texts.scss';

const QuestCompareTexts = ({ text, options, answer, handle }) => {
    return (
        <Div className="quest">
            <div className="quest__title">{text || 'Выберите подходящий вариант'}</div>
            <div className="quest__box">
                <div className="quest__box-wrap quest-texts">
                    {options.map((option) => (
                        <Radio
                            name="radio"
                            key={option.value}
                            value={option.value}
                            checked={answer === option.value}
                            onChange={() => handle(option.value)}
                        >
                            {option.text}
                            <div className="quest-image">
                                {answer === option.value && <div className="quest-image__overlay" />}
                            </div>
                        </Radio>
                    ))}
                    <div style={{ height: 120 }} />
                </div>
            </div>
        </Div>
    );
};

QuestCompareTexts.propTypes = {
    text: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string,
            value: PropTypes.string,
        }),
    ),
    answer: PropTypes.any,
    handle: PropTypes.func.isRequired,
};

QuestCompareTexts.defaultProps = {
    options: [
        {
            text: 'Это котик',
            value: '1',
        },
        {
            text: 'Это не котик',
            value: '2',
        },
    ],
    answer: null,
};

export default QuestCompareTexts;
