import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Div from '@vkontakte/vkui/dist/components/Div/Div';
import Search from '@vkontakte/vkui/dist/components/Search/Search';
import Checkbox from '@vkontakte/vkui/dist/components/Checkbox/Checkbox';

import '../../styles/quest.scss';
import '../../styles/quest-group.scss';

const QuestCheck = ({ image, options, answer, handle, showAsLabels }) => {
    const [searchValue, setSearchValue] = useState('');

    const Wrapper = p => {
        if (showAsLabels) {
            return React.createElement(Div, { className: 'radio-check' }, p.children);
        }
        return React.createElement('div', {}, p.children);
    };

    return (
        <>
            <Div className="quest">
                <div className="quest__title">Что на картинке?</div>
                <div className="quest__box">
                    <div className="quest__box-wrap">
                        <div className="quest-image" style={{ backgroundImage: `url('${image}')` }} />
                    </div>
                </div>
            </Div>
            <div className="quest-group">
                <Div className="quest-group__title">Выберите подходящие варианты</Div>
                <div className="quest-group__content">
                    <Search theme="default" placeholder="Поиск" value={searchValue} onChange={setSearchValue} />
                    <Wrapper>
                        {options
                            .filter(o => o.name.toLowerCase().includes(searchValue.toLowerCase()))
                            .map(option => (
                                <Checkbox
                                    key={option.value}
                                    checked={answer.indexOf(option.value) !== -1}
                                    onClick={() => {
                                        if (answer.indexOf(option.value) === -1) {
                                            handle([...answer, option.value]);
                                        } else {
                                            handle(answer.filter(x => x !== option.value));
                                        }
                                    }}
                                >
                                    {option.name}
                                </Checkbox>
                            ))}
                        {options.filter(o => o.name.toLowerCase().includes(searchValue.toLowerCase())).length === 0 && (
                            <Div>Ничего не найдено</Div>
                        )}
                    </Wrapper>
                </div>
            </div>
        </>
    );
};

QuestCheck.propTypes = {
    image: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.string,
        }),
    ),
    showAsLabels: PropTypes.bool,
    answer: PropTypes.any,
    handle: PropTypes.func.isRequired,
};

QuestCheck.defaultProps = {
    image: 'https://picsum.photos/200/300?random=6',
    options: [
        {
            name: 'Это котик',
            value: '1',
        },
        {
            name: 'Жирный котик',
            value: '2',
        },
        {
            name: 'Это не котик',
            value: '3',
        },
        {
            name: 'Животное',
            value: '4',
        },
        {
            name: 'Человек',
            value: '5',
        },
        {
            name: 'Криворукий разработчик',
            value: '6',
        },
    ],
    showAsLabels: false,
    answer: [],
};

export default QuestCheck;
